import { render, staticRenderFns } from "./blood-oxygen.vue?vue&type=template&id=b8337d88&scoped=true"
import script from "./blood-oxygen.vue?vue&type=script&lang=js"
export * from "./blood-oxygen.vue?vue&type=script&lang=js"
import style0 from "./blood-oxygen.vue?vue&type=style&index=0&id=b8337d88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8337d88",
  null
  
)

export default component.exports